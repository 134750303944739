<template>
  <v-layout v-if="!isLoaded" column fill-height align-center justify-center>
    <AppErrorCard
      v-if="hasError"
      entity="vision & plan"
      :is-anonymous-user="loggedInUser.isAnonymous"
      :is-invited="!!$route.query.invited"
      :invitee-email="$route.query.email"
      :show-auth-loader="showAuthLoader"
      @reload="reloadVTODetails"
      @login-with-google="$emit('login-with-google')"
      @login-with-microsoft="$emit('login-with-microsoft')"
      @login-with-email-pass="$emit('login-with-email-pass', $event)"
      @signup-with-email-pass="$emit('signup-with-email-pass', $event)"
      @reset-password="$emit('reset-password', $event)"
      @update-password="$emit('update-password', $event)"
    />

    <AppLoader v-else />
  </v-layout>
  <div v-else>
    <AppPageToolbar
      ref="toolbar"
      entity="vision & plan"
      :entity-name="meta.name"
      :visibility="meta.visibility"
      :users="data.users"
      :domains="meta.permissions.domains"
      :logged-in-user="loggedInUser"
      printable
      @update-name="updateVTOName"
      @update-privacy="updatePrivacy"
      @update-permissions="updatePermissions"
      @delete="deleteVTO"
      @set-message="setMessage"
      @open-import-export-dialog="openImportExportModal"
      @print="printVTO"
    >
      <div
        v-if="hasPreviousVersions"
        slot="left"
        class="d-flex align-center ml-5 eos-vto__version-select"
      >
        <div><span class="text-body-1">Version:</span></div>
        <v-select
          :value="currentVersion"
          :items="vtoVersions"
          :menu-props="{ 'offset-y': true }"
          dense
          solo
          flat
          hide-details
          @change="updateCurrentVersion"
        />
      </div>
    </AppPageToolbar>

    <v-tabs v-model="selectedTab" grow height="42px" class="hidden-print">
      <v-tab>
        <v-icon left>mdi-eye</v-icon>
        Vision
      </v-tab>
      <v-tab>
        <v-icon left>mdi-cog</v-icon>
        Tactics
      </v-tab>
    </v-tabs>
    <hr class="hidden-print" />

    <div class="pt-0 eos-vto__wrapper" :class="{ 'eos-vto__version--old': !isViewingCurrentVersion }">
      <!-- vision -->
      <v-row v-if="isPrinting" justify="center" class="show-print">
        <v-tab class="mt-3">
          <v-icon left>mdi-eye</v-icon>
          Vision
        </v-tab>
      </v-row>
      <v-row
        v-if="selectedTab === 0 || isPrinting"
        no-gutters
        class="eos-vto__vision"
        :class="{ 'mt-3': isPrinting }"
      >
        <v-col cols="12" md="8" class="d-flex flex-column flex-grow-1">
          <!-- core values -->
          <v-flex class="d-flex flex-row">
            <div class="d-flex flex-column justify-center align-center grey lighten-3 eos-vto__core-values">
              <v-icon left color="secondary" class="mb-1" v-text="'$vuetify.icons.tw-core-values'" />
              <strong class="text-uppercase">Core Values</strong>
            </div>

            <div class="d-flex flex-grow-1 eos-vto__content--editable">
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.coreValues || isEditing('coreValue'))"
                v-model="data.coreValues"
                :placeholder="`1. Result driven \n2. Customer centric \n3. Strive for excellence`"
                rows="3"
                tabindex="1"
                class="pa-3"
                outlined
                dense
                hide-details
                :autofocus="isEditing('coreValue')"
                @focus="startEditing('coreValue')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                class="pa-3 eos-vto__area--editable"
                v-html="$options.filters.nlToList(data.coreValues, true)"
                @click="startEditing('coreValue')"
              >
              </div>
            </div>
          </v-flex>
          <hr />

          <!-- focus -->
          <v-flex class="d-flex flex-row">
            <div class="d-flex flex-column justify-center align-center grey lighten-3 eos-vto__core-focus">
              <v-icon left color="secondary" class="mb-1" v-text="'$vuetify.icons.tw-core-focus'" />
              <strong class="text-uppercase">Focus</strong>
            </div>

            <div class="d-flex flex-column py-3 flex-grow-1 eos-vto__content--editable">
              <!-- purpose -->
              <p class="px-4 mb-1"><strong>Purpose/Passion:</strong></p>
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.coreFocus.purpose || isEditing('purpose'))"
                v-model="data.coreFocus.purpose"
                placeholder="Purpose/Passion"
                rows="1"
                tabindex="2"
                class="px-4"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('purpose')"
                @focus="startEditing('purpose')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                class="px-4 py-3 eos-vto__area--editable"
                @click="startEditing('purpose')"
              >{{ data.coreFocus.purpose }}</div>
              <br/>

              <!-- niche -->
              <p class="px-4 mb-1"><strong>Our Niche:</strong></p>
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.coreFocus.niche || isEditing('niche'))"
                v-model="data.coreFocus.niche"
                placeholder="Our Niche"
                rows="1"
                tabindex="3"
                class="px-4"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('niche')"
                @focus="startEditing('niche')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                class="px-4 py-3 eos-vto__area--editable"
                @click="startEditing('niche')"
              >{{ data.coreFocus.niche }}</div>
            </div>
          </v-flex>
          <hr />

          <!-- long-term target -->
          <v-flex class="d-flex flex-row">
            <div class="d-flex flex-column justify-center align-center grey lighten-3 eos-vto__target">
              <v-icon left color="secondary" class="mb-1" v-text="'$vuetify.icons.tw-target'" />
              <strong class="text-center text-uppercase">Long-term <br/> Key Priorities</strong>
            </div>

            <div class="d-flex flex-column flex-grow-1 eos-vto__content--editable">
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.tenYearTarget || isEditing('10YearTarget'))"
                v-model="data.tenYearTarget"
                placeholder="Where do you want to be in next ten years?"
                rows="3"
                tabindex="4"
                class="pa-3"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('10YearTarget')"
                @focus="startEditing('10YearTarget')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <p v-else class="px-4 py-3 mb-0 eos-vto__area--editable" @click="startEditing('10YearTarget')">{{ data.tenYearTarget }}</p>
            </div>
          </v-flex>
          <hr />

          <!-- marketing strategy -->
          <v-flex class="d-flex flex-row">
            <div class="d-flex flex-column justify-center align-center grey lighten-3 eos-vto__marketing-starategy">
              <v-icon left color="secondary" class="mb-1" v-text="'$vuetify.icons.tw-marketing-strategy'" />
              <strong class="text-center text-uppercase">Marketing <br/> Strategy</strong>
            </div>

            <div class="d-flex flex-column flex-grow-1 py-3 eos-vto__content--editable">
              <!-- target market -->
              <p class="px-4 mb-1"><strong>Target Market:</strong></p>
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.marketingStrategy.targetMarket || isEditing('targetMarket'))"
                v-model="data.marketingStrategy.targetMarket"
                :placeholder="`1. Digital agencies \n2. Small/Mid size companies`"
                rows="3"
                class="px-4"
                tabindex="5"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('targetMarket')"
                @focus="startEditing('targetMarket')"
                @change="saveVTO"
                @blur="stopEditing"
              />
              <div
                v-else
                class="pa-3 eos-vto__area--editable"
                v-html="$options.filters.nlToList(data.marketingStrategy.targetMarket, true)"
                @click="startEditing('targetMarket')"
              >
              </div>
              <br/>

              <!-- uniques -->
              <p class="px-4 mb-1"><strong>Differentiators:</strong></p>
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.marketingStrategy.uniques || isEditing('uniques'))"
                v-model="data.marketingStrategy.uniques"
                :placeholder="`1. Honest and open \n2. Passionate about what we do \n3. Ten years of expertise in field`"
                rows="3"
                tabindex="6"
                class="px-4"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('uniques')"
                @focus="startEditing('uniques')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                class="pa-3 eos-vto__area--editable"
                v-html="$options.filters.nlToList(data.marketingStrategy.uniques, true)"
                @click="startEditing('uniques')"
              >
              </div>
              <br/>

              <!-- proven process -->
              <p class="px-4 mb-1"><strong>Proven Process:</strong></p>
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.marketingStrategy.provenProcess || isEditing('provenProcess'))"
                v-model="data.marketingStrategy.provenProcess"
                placeholder="Proven Process"
                rows="2"
                tabindex="7"
                class="px-4"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('provenProcess')"
                @focus="startEditing('provenProcess')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                class="px-5 py-3 eos-vto__area--editable"
                @click="startEditing('provenProcess')"
              >{{ data.marketingStrategy.provenProcess }}</div>
              <br/>

              <!-- guarantee -->
              <p class="px-4 mb-1"><strong>Guarantee:</strong></p>
              <v-textarea
                v-if="isViewingCurrentVersion && (!data.marketingStrategy.guarantee || isEditing('guarantee'))"
                v-model="data.marketingStrategy.guarantee"
                placeholder="Guarantee"
                rows="2"
                tabindex="8"
                class="px-4"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing('guarantee')"
                @focus="startEditing('guarantee')"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                class="px-5 py-3 eos-vto__area--editable"
                @click="startEditing('guarantee')"
              >{{ data.marketingStrategy.guarantee }}</div>
            </div>
          </v-flex>
        </v-col>

        <!-- 3 year objectives -->
        <v-col cols="12" md="4">
          <div class="d-flex align-center justify-center px-5 py-3 grey lighten-3 text-center">
            <v-icon left color="secondary" class="mr-2" v-text="'$vuetify.icons.tw-picture'" />
            <strong class="text-uppercase">3-Year Objectives</strong>
          </div>
          <hr />

          <v-flex class="py-5">
            <!-- future date -->
            <div class="px-4">
              <strong class="mr-2">Future Date:</strong>
              <AppDatePicker
                :date="data.threeYearPicture.futureDate"
                @change="updateFutureDate('threeYearPicture', $event)"
              />
            </div>

            <!-- extra fields -->
            <div
              v-for="(extraField, index) in data.threeYearPicture.extraFields"
              :key="`three-year-extra-field-${index}`"
            >
              <div class="d-flex px-4">
                <v-text-field
                  v-model="extraField.title"
                  placeholder="Revenue"
                  class="mr-2"
                  dende
                  hide-details
                  @change="saveVTO"
                />
                <v-text-field
                  v-model="extraField.value"
                  placeholder="$1m"
                  dende
                  hide-details
                  @change="saveVTO"
                />
                <v-btn icon class="mt-4 align-self-center" @click="removeExtraField('threeYearPicture', index)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <br/>

            <v-btn depressed x-small class="d-flex align mx-auto" @click="addExtraField('threeYearPicture')">
              <v-icon x-small class="ml-n1 mr-1">mdi-plus</v-icon>
              Add
            </v-btn>
            <br />

            <!-- measurables -->
            <p class="px-4 mb-1"><strong>Measurables:</strong></p>
            <v-textarea
              v-if="isViewingCurrentVersion && (!data.threeYearPicture.measurables || isEditing('threeYearMeasurable'))"
              v-model="data.threeYearPicture.measurables"
              :placeholder="`• 3x profit \n• Recruit fifty developers`"
              rows="2"
              tabindex="9"
              class="px-4"
              outlined
              full-width
              dense
              hide-details
              :autofocus="isEditing('threeYearMeasurable')"
              @focus="startEditing('threeYearMeasurable')"
              @blur="stopEditing"
              @change="saveVTO"
            />
            <div
              v-else
              class="pa-3 eos-vto__area--editable"
              v-html="$options.filters.nlToList(data.threeYearPicture.measurables)"
              @click="startEditing('threeYearMeasurable')"
            ></div>
            <br />

            <!-- what does it looks like -->
            <p class="px-4 mb-1"><strong>What Does It Look Like:</strong></p>
            <v-textarea
              v-if="isViewingCurrentVersion && (!data.threeYearPicture.whatDoesItLookLike || isEditing('whatDoesItLookLike'))"
              v-model="data.threeYearPicture.whatDoesItLookLike"
              :placeholder="`• Automate workflow \n• Get 2x new projects \n• Introduce new HR policy`"
              rows="15"
              tabindex="10"
              class="px-4"
              outlined
              full-width
              dense
              hide-details
              :autofocus="isEditing('whatDoesItLookLike')"
              @focus="startEditing('whatDoesItLookLike')"
              @blur="stopEditing"
              @change="saveVTO"
            />
            <div
              v-else
              class="pa-3 eos-vto__area--editable"
              v-html="$options.filters.nlToList(data.threeYearPicture.whatDoesItLookLike)"
              @click="startEditing('whatDoesItLookLike')"
            >
            </div>
          </v-flex>
        </v-col>
      </v-row>

      <!-- tactics -->
      <v-row v-if="isPrinting" justify="center" class="mt-12 show-print print-new-page">
        <v-tab>
          <v-icon left>mdi-cog</v-icon>
          Tactics
        </v-tab>
      </v-row>
      <v-row
        v-if="selectedTab === 1 || isPrinting"
        no-gutters
        class="eos-vto__traction"
        :class="{ 'mt-3': isPrinting }"
      >
        <!-- one year plan -->
        <v-col cols="12" md="4">
          <div class="d-flex align-center justify-center px-5 py-3 grey lighten-3 text-center">
            <v-icon left color="secondary" class="mr-2" v-text="'$vuetify.icons.tw-plan'" />
            <strong class="text-uppercase">1 Year Plan</strong>
          </div>
          <hr />

          <v-flex class="py-5">
            <!-- future date -->
            <div class="px-4">
              <strong class="mr-2">Future Date:</strong>
              <AppDatePicker
                :date="data.oneYearPlan.futureDate"
                @change="updateFutureDate('oneYearPlan', $event)"
              />
            </div>

            <!-- extra fields -->
            <div
              v-for="(extraField, index) in data.oneYearPlan.extraFields"
              :key="`one-year-plan-extra-field-${index}`"
            >
              <div class="d-flex px-4">
                <v-text-field
                  v-model="extraField.title"
                  placeholder="Revenue"
                  class="mr-2"
                  dende
                  hide-details
                  @change="saveVTO"
                />
                <v-text-field
                  v-model="extraField.value"
                  placeholder="$1m"
                  dende
                  hide-details
                  @change="saveVTO"
                />
                <v-btn icon class="mt-4 align-self-center" @click="removeExtraField('oneYearPlan', index)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <br/>

            <v-btn depressed x-small class="d-flex mx-auto" @click="addExtraField('oneYearPlan')">
              <v-icon x-small class="ml-n1 mr-1">mdi-plus</v-icon>
              Add
            </v-btn>
            <br />

            <!-- measurables -->
            <p class="px-4 mb-1"><strong>Measurables:</strong></p>
            <v-textarea
              v-if="isViewingCurrentVersion && (!data.oneYearPlan.measurables || isEditing('oneYearMeasurable'))"
              v-model="data.oneYearPlan.measurables"
              :placeholder="`• $100k MRR \n• Improve web application performance by 100%`"
              rows="2"
              tabindex="11"
              class="px-4"
              outlined
              full-width
              dense
              hide-details
              :autofocus="isEditing('oneYearMeasurable')"
              @focus="startEditing('oneYearMeasurable')"
              @blur="stopEditing"
              @change="saveVTO"
            />

            <div
              v-else
              class="pa-3 eos-vto__area--editable"
              v-html="$options.filters.nlToList(data.oneYearPlan.measurables)"
              @click="startEditing('oneYearMeasurable')"
            ></div>
            <br />

            <p class="px-4 mb-0"><strong>Goals for the year:</strong></p>
            <div
              v-for="(goal, index) in data.oneYearPlan.goals"
              :key="`eos-vto-goal-${index}`"
              class="d-flex align-center px-4 mt-2"
              :class="{ 'eos-vto__area--editable': data.oneYearPlan.goals[index].title && !isEditing(`goal-${index}`) }"
            >
              <v-checkbox
                v-model="goal.isCompleted"
                height="8"
                :color="goal.isCompleted && 'grey' || 'primary'"
                @change="saveVTO"
              />
              <v-text-field
                v-if="isViewingCurrentVersion && (!data.oneYearPlan.goals[index].title || isEditing(`goal-${index}`))"
                v-model="data.oneYearPlan.goals[index].title"
                placeholder="Goal"
                rows="15"
                class="d-flex"
                outlined
                full-width
                dense
                hide-details
                autofocus
                @focus="startEditing(`goal-${index}`)"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                :class="goal.isCompleted && 'grey--text' || 'black--text'"
                class="d-flex flex-grow-1 ml-2"
                v-text="goal.title"
                @click.stop="startEditing(`goal-${index}`)"
              ></div>
              <v-btn icon @click="deleteGoal(index)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>

            <v-btn depressed x-small class="d-flex mx-auto mt-5" @click="addGoal">
              <v-icon x-small class="ml-n1 mr-1">mdi-plus</v-icon>
              Add Goal
            </v-btn>
          </v-flex>
        </v-col>

        <!-- rocks -->
        <v-col cols="12" md="4" class="eos-vto__content--editable">
          <div class="d-flex align-center justify-center px-5 py-3 grey lighten-3 text-center">
            <v-icon left color="secondary" class="mr-2" v-text="'$vuetify.icons.tw-rocks'" />
            <strong class="text-uppercase">Key Priorities</strong>
          </div>
          <hr />

          <v-flex class="py-5">
            <!-- future date -->
            <div class="px-4">
              <strong class="mr-2">Future Date:</strong>
              <AppDatePicker
                :date="data.rocks.futureDate"
                @change="updateFutureDate('rocks', $event)"
              />
            </div>

            <!-- extra fields -->
            <div
              v-for="(extraField, index) in data.rocks.extraFields"
              :key="`rocks-extra-field-${index}`"
            >
              <div class="d-flex px-4">
                <v-text-field
                  v-model="extraField.title"
                  placeholder="Profit"
                  class="mr-2"
                  dende
                  hide-details
                  @change="saveVTO"
                />
                <v-text-field
                  v-model="extraField.value"
                  placeholder="$1m"
                  dende
                  hide-details
                  @change="saveVTO"
                />
                <v-btn icon class="mt-4 align-self-center" @click="removeExtraField('rocks', index)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
            <br/>

            <v-btn depressed x-small class="d-flex mx-auto" @click="addExtraField('rocks')">
              <v-icon x-small class="ml-n1 mr-1">mdi-plus</v-icon>
              Add
            </v-btn>
            <br />

            <!-- measurables -->
            <p class="px-4 mb-1"><strong>Measurables:</strong></p>
            <v-textarea
              v-if="isViewingCurrentVersion && (!data.rocks.measurables || isEditing('rockMeasurable'))"
              v-model="data.rocks.measurables"
              :placeholder="`• Hire new CFO \n• Re-structure development team`"
              rows="2"
              tabindex="12"
              class="px-4"
              outlined
              full-width
              dense
              hide-details
              :autofocus="isEditing('rockMeasurable')"
              @focus="startEditing('rockMeasurable')"
              @blur="stopEditing"
              @change="saveVTO"
            />
            <div
              v-else
              class="pa-3 eos-vto__area--editable"
              v-html="$options.filters.nlToList(data.rocks.measurables)"
              @click="startEditing('rockMeasurable')"
            >
            </div>
            <br />

            <!-- rocks -->
            <p class="px-4 mb-2"><strong>Key priorities for the quarter:</strong></p>
            <div
              v-for="(rock, index) in data.rocks.quaterlyRocks"
              :key="`eos-vto-rock-${index}`"
              class="d-flex align-center px-4 py-2"
              :class="{
                'eos-vto__area--editable': (rock.title || rock.rock) && !isEditing(`rock-${index}`)
              }"
            >
              <v-icon
                class="mb-1"
                size="24px"
                dark
                :color="(rock.status === 'Complete' || rock.isCompleted) ? 'grey' : 'rgba(0, 0, 0, 0.55)'"
                @click="completeRock(rock)"
              >
                {{ rock.status === 'Complete' || rock.isCompleted ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
              </v-icon>

              <div
                :class="(rock.status === 'Complete' || rock.isCompleted) ? 'grey--text' : 'black--text'"
                :style="{
                  'text-decoration': rock.status === 'Complete' || rock.isCompleted ? 'line-through' : ''
                }"
                class="d-flex flex-grow-1 ml-2"
                v-text="rock.rock || rock.title"
                @click.stop="editRock(rock)"
              />

              <div slot="actions" class="d-flex align-center">
                <v-tooltip v-if="userRocktools.length > 0" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" class="mr-1" @click="linkWithRocktool(rock)">mdi-bullseye-arrow</v-icon>
                  </template>
                  <span>Link with Key Priorities</span>
                </v-tooltip>
                <v-tooltip v-if="userL10s.length > 0" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" @click="linkWithL10s(rock)">mdi-calendar</v-icon>
                  </template>
                  <span>Link with Weekly Meetings</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" class="ml-1" @click="deleteRock(index)">mdi-delete</v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </div>

            <v-btn depressed x-small class="d-flex mx-auto mt-5" @click="addRock()">
              <v-icon x-small class="ml-n1 mr-1">mdi-plus</v-icon>
              Add Key Priority
            </v-btn>
          </v-flex>
        </v-col>

        <!-- issues -->
        <v-col cols="12" md="4">
          <div class="d-flex align-center justify-center px-5 py-3 grey lighten-3 text-center">
            <v-icon left color="secondary" class="mr-2" v-text="'$vuetify.icons.tw-issue-list'" />
            <strong class="text-uppercase">Issue List</strong>
          </div>
          <hr />

          <v-flex :class="{ 'pt-3': data.issues.length === 0 }">
            <div
              v-for="(obj, index) in data.issues"
              :key="`eos-vto-issue-${index}`"
              class="d-flex align-center px-4 mt-2"
              :class="{ 'eos-vto__area--editable': data.issues[index].issue && !isEditing(`issue-${index}`) }"
            >
              <v-checkbox
                v-model="obj.isCompleted"
                height="8"
                :color="obj.isCompleted && 'grey' || 'primary'"
                @change="saveVTO"
              />
              <v-text-field
                v-if="isViewingCurrentVersion && (!data.issues[index].issue || isEditing(`issue-${index}`))"
                v-model="data.issues[index].issue"
                placeholder="Issue"
                rows="15"
                class="d-flex"
                outlined
                full-width
                dense
                hide-details
                :autofocus="isEditing(`issue-${index}`)"
                @focus="startEditing(`issue-${index}`)"
                @blur="stopEditing"
                @change="saveVTO"
              />
              <div
                v-else
                :class="obj.isCompleted && 'grey--text' || 'black--text'"
                class="d-flex flex-grow-1 ml-2"
                v-text="obj.issue"
                @click.stop="startEditing(`issue-${index}`)"
              ></div>

              <v-btn icon @click="deleteIssue(index)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>

            <v-btn depressed x-small class="d-flex mx-auto mt-3" @click="addIssue">
              <v-icon x-small class="ml-n1 mr-1">mdi-plus</v-icon>
              Add Issue
            </v-btn>
          </v-flex>
        </v-col>
      </v-row>
    </div>

    <KeyPriorityDialogForm
      v-model="showDialogRockForm"
      :title="rockFields.parentRockId ? 'Deliverable' : 'Key Priority'"
      :users="data.users"
      :fields="rockFields"
      manage-users-btn-text="Manage Users"
      @manage-users="$refs.toolbar.managePermissions()"
      @save="updateRockFields($event, false)"
      @save-next="updateRockFields($event, true)"
      @set-message="setMessage"
      @close="closeDialogRockForm"
    />

    <KeyPriorityDialogLinks
      v-if="showLinksDialog"
      :fetching-items="isFetchingLinkedItems"
      :show-loader="isUpdatingLinks"
      :title="linkDialogTitle"
      :data="linkDialogData"
      :selected-ids="selectedIds"
      @update-relation="updateKeyPriorityRelations"
      @close="closeLinksDialog"
    />

    <AppDialogJSONImportExport
      v-if="showImportExportModal"
      title="Vision & Plan Data"
      :allow-update="isViewingCurrentVersion"
      :dataJSON="dataJSON"
      :show-loader="isUpdatingVTOJSON"
      @update-data="updateKeyPrioritiesJSON"
      @close="closeImportExportModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppPageToolbar from '@/components/shared/toolbars/AppPageToolbar'
import AppDatePicker from '@/components/shared/datepickers/AppDatePicker'
import AppDialogJSONImportExport from '@/components/shared/dialogs/AppDialogJSONImportExport'
import AppErrorCard from '@/components/shared/errors/AppErrorCard'
import KeyPriorityDialogForm from '@/components/keyPriorities/dialogs/KeyPriorityDialogForm'
import KeyPriorityDialogLinks from '@/components/keyPriorities/dialogs/KeyPriorityDialogLinks'

import DataManager from '@/helpers/dataManager'
import FIREBASE_PERMISSION_ERROR_CODES from '@/enums/firebasePermissionErrorCodes'
import KEY_PRIORITIES_STATUSES from '@/enums/keyPriorityStatuses'

const ADD_KP = 'add'
const REMOVE_KP = 'remove'

export default {
  name: 'PageVTO',
  components: {
    AppPageToolbar,
    AppDatePicker,
    AppDialogJSONImportExport,
    AppErrorCard,
    KeyPriorityDialogForm,
    KeyPriorityDialogLinks
  },
  metaInfo () {
    return {
      title: 'Vision and Plans'
    }
  },
  props: {
    showAuthLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      selectedTab: 0,
      fieldEditing: '',
      meta: {
        name: '',
        visibility: 'public',
        permissions: {
          domains: []
        }
      },
      data: {
        users: [],
        coreValues: '',
        coreFocus: {
          purpose: '',
          niche: ''
        },
        tenYearTarget: '',
        marketingStrategy: {
          targetMarket: '',
          uniques: '',
          provenProcess: '',
          guarantee: ''
        },
        threeYearPicture: {
          futureDate: '',
          extraFields: [],
          measurables: '',
          whatDoesItLookLike: ''
        },
        oneYearPlan: {
          futureDate: '',
          revenue: '',
          profit: '',
          measurables: '',
          goals: []
        },
        rocks: {
          futureDate: '',
          measurables: '',
          quarterlyRocks: []
        },
        issues: [],
        previousVersions: []
      },
      currentVersion: 1,
      draftVTO: null,
      previousVersions: [],
      bumpVersionOnChange: false,
      isLoaded: false,
      hasError: false,
      isPrinting: false,
      isUpdatingVTOJSON: false,
      showImportExportModal: false,
      dataJSON: '',
      rockFields: {
        email: '',
        rock: '',
        description: '',
        startDate: '',
        endDate: '',
        type: '',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0,
        deliverables: []
      },
      defaultRock: {
        email: '',
        rock: '',
        description: '',
        startDate: '',
        endDate: '',
        type: '',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0,
        deliverables: []
      },
      showDialogRockForm: false,
      isFetchingLinkedItems: false,
      isUpdatingLinks: false,
      linkedRocktoolsWithExistingKP: [],
      linkedL10sWithExistingKP: [],
      keyPriorityIdToLinkWithRocktool: null,
      keyPriorityIdToLinkWithL10: null
    }
  },
  computed: {
    ...mapGetters(['loggedInUser', 'loggedInUserData']),

    hasPreviousVersions () {
      return this.previousVersions.length > 1
    },

    vtoVersions () {
      const prevVerions = this.previousVersions
      const versions = []
      for (let i = 0; i < prevVerions.length; i++) {
        versions.push(i + 1)
      }

      return versions.reverse()
    },

    isViewingCurrentVersion () {
      return (!this.hasPreviousVersions || this.currentVersion === this.previousVersions.length) && !this.isPrinting
    },

    userRocktools () {
      return this.loggedInUserData.linkedKeyPriorities
    },

    userL10s () {
      return this.loggedInUserData.linkedLevel10s
    },

    showLinksDialog () {
      return !!(this.keyPriorityIdToLinkWithRocktool || this.keyPriorityIdToLinkWithL10)
    },

    linkDialogTitle () {
      return this.keyPriorityIdToLinkWithRocktool ? 'Select Key priorities' : 'Select Weekly Meetings'
    },

    linkDialogData () {
      return this.keyPriorityIdToLinkWithRocktool ? this.userRocktools : this.userL10s
    },

    selectedIds () {
      return this.keyPriorityIdToLinkWithRocktool ? this.linkedRocktoolsWithExistingKP : this.linkedL10sWithExistingKP
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        this.loadVTODetailsById(to.params.vtoId)
      }
    }
  },
  created () {
    window.addEventListener('beforeprint', this.beforePrint)
    window.addEventListener('afterprint', this.afterPrint)
  },
  destroyed () {
    // destroy will prevent memory leaks
    this.dataManager.destroy()
    window.removeEventListener('beforeprint', this.beforePrint)
    window.removeEventListener('afterprint', this.afterPrint)
  },
  methods: {
    ...mapActions([
      'toggleKeypriorityForItems',
      'syncKeypriority',
      'setMessage'
    ]),

    loadVTODetailsById (vtoId) {
      this.vtoId = null // will stop overwriting saves while we load
      this.dataManager.cancelSubscriptions()

      const handlerError = (error) => {
        this.hasError = true
        this.setMessage({ type: 'error', message: error.message })
        // remove from list if do not have access
        if (FIREBASE_PERMISSION_ERROR_CODES.includes(error.code)) {
          this.dataManager.updateUserReferenceToObject('vtos', vtoId)
        }
      }

      try {
        this.dataManager.syncObject('vtos', vtoId, (error, vtoObject) => {
          if (error) return handlerError(error)

          this.vtoId = vtoId
          this.meta = { ...this.meta, ...vtoObject.meta }

          // update current version in background if user is viewing prev version
          if (!this.isViewingCurrentVersion) {
            return this.$set(this.previousVersions, this.previousVersions.length - 1, Object.assign({}, vtoObject.data))
          }

          this.data = { ...this.data, ...vtoObject.data }
          this.setVersionInfo()
          this.checkFoVersionUpgrade()

          this.isLoaded = true
          this.hasError = false
        })
      } catch (error) {
        handlerError(error)
      }
    },

    reloadVTODetails () {
      this.loadVTODetailsById(this.$route.params.vtoId)
    },

    async saveVTO (showSuccessMessage = true) {
      try {
        this.stopEditing()
        if (!this.isLoaded || this.vtoId === null || !this.loggedInUser) return

        // save version if day diff is > 0 and actual content has edited
        if (this.draftVTO && showSuccessMessage) {
          const data = { ...this.draftVTO, createdAt: new Date().getTime() }
          // remove unnecessary data
          delete data.users
          delete data.previousVersions
          this.data.previousVersions.push(data)
        }

        await this.dataManager.saveObject('vtos', this.vtoId, { meta: this.meta, data: this.data })
        if (showSuccessMessage) this.setMessage({ type: 'success', message: 'Data updated' })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error saving data: ${error.message}` })
      }
    },

    setVersionInfo () {
      this.previousVersions = this.data.previousVersions.slice()
      this.previousVersions.push(this.data) // push current version
      this.currentVersion = this.data.previousVersions.length + 1
    },

    // decide if version need to update
    // version will be updated if it was last update a day ago atleast
    //
    checkFoVersionUpgrade () {
      // check when it was updated last time
      const timestampDiff = (new Date().getTime() - this.meta.lastUpdate.timestamp)
      this.bumpVersionOnChange = Math.floor(timestampDiff / 1000 / 60 / 60 / 24) > 0
      // should be saved upon change
      this.draftVTO = this.bumpVersionOnChange ? JSON.parse(JSON.stringify(this.data)) : null
      this.$analytics('visionandplan_updated_after_day', { id: this.vtoId })
    },

    updateCurrentVersion (version) {
      this.currentVersion = version
      this.data = { ...this.data, ...this.previousVersions[version - 1] }
    },

    startEditing (field) {
      if (!this.isViewingCurrentVersion) return
      this.fieldEditing = field
    },

    isEditing (field) {
      return this.fieldEditing === field
    },

    stopEditing () {
      this.fieldEditing = ''
    },

    updateVTOName (name) {
      try {
        this.$set(this.meta, 'name', name)
        this.saveVTO(false)
        // We need to also update the name in the list
        this.dataManager.updateObjectNameInList('vtos', this.vtoId, this.meta.name)
      } catch (error) {
        this.setMessage({ type: 'error', message: error.message })
      }
    },

    updatePrivacy (visibility) {
      this.$set(this.meta, 'visibility', visibility)
      this.saveVTO(false)
      this.$analytics('visionandplan_changed_privacy', { type: visibility })
    },

    updatePermissions ({ domains, usersMeta, users }) {
      this.$set(this.meta, 'permissions', { domains, ...usersMeta })
      this.$set(this.data, 'users', users)
      this.saveVTO(false)
    },

    async deleteVTO () {
      try {
        this.isLoaded = false
        this.dataManager.cancelSubscriptions()
        await this.dataManager.deleteObject('vtos', this.vtoId)
        this.$router.push({ name: 'home' })
      } catch (error) {
        this.isLoaded = true
        this.setMessage({ type: 'error', message: `Error deleting Vision & Plan: ${error.message}` })
      }
    },

    updateFutureDate (field, date) {
      this.$set(this.data[field], 'futureDate', date)
      this.saveVTO()
    },

    addExtraField (field) {
      this.data[field].extraFields.push({ title: '', value: '' })
    },

    removeExtraField (field, index) {
      this.data[field].extraFields.splice(index, 1)
      this.saveVTO(false)
    },

    addIssue () {
      this.data.issues.push({ isCompleted: false, issue: '' })
    },

    deleteIssue (index) {
      this.data.issues.splice(index, 1)
      this.saveVTO()
    },

    addGoal () {
      this.data.oneYearPlan.goals.push({ isCompleted: false, title: '' })
    },

    deleteGoal (index) {
      this.data.oneYearPlan.goals.splice(index, 1)
      this.saveVTO()
    },

    addRock (item = {}) {
      this.rockFields = {
        ...this.defaultRock,
        email: item.email || this.loggedInUser.email,
        type: item.type || 'Company',
        parentRockId: item.id || null,
        startDate: item.startDate || '',
        endDate: item.endDate || ''
      }
      this.showDialogRockForm = true
    },

    editRock (item = {}) {
      if (!this.isViewingCurrentVersion) return
      if (!item.rock && item.title) item.rock = item.title
      if (!item.id) item.id = this.$helpers.generateUniqueId()
      this.rockFields = { ...this.defaultRock, ...item }
      this.showDialogRockForm = true
    },

    updateRockFields (rockFields, keepForNext) {
      const rocks = this.data.rocks.quaterlyRocks
      delete rockFields.title
      this.rockFields = Object.assign({}, rockFields)

      // validate
      if (!this.rockFields.email || !this.rockFields.rock) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }

      if (this.rockFields.id) {
        const index = rocks.findIndex(rock => rock.id === this.rockFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Key priority is deleted' })
        this.$set(rocks, index, this.rockFields)
        // sync
        this.syncKeypriority({
          keyPriority: this.rockFields,
          groupId: this.vtoId
        })
      } else {
        const newRock = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.rockFields)
        rocks.push(newRock)
      }

      this.saveVTO()
      if (keepForNext) {
        this.$set(this.rockFields, 'rock', '')
        this.$set(this.rockFields, 'description', '')
      } else {
        this.closeDialogRockForm()
      }
    },

    closeDialogRockForm () {
      this.showDialogRockForm = false
    },

    async loadLinkedRocktoolsAndL10s (rockId, createIfNotExists = false) {
      try {
        this.isFetchingLinkedItems = true
        const object = await this.dataManager.loadObject('kpMapping', rockId)
        if (!object.exists) {
          // will help us to map keypriority to kps and vtos
          if (createIfNotExists) {
            await this.dataManager.createObject('kpMapping', {
              id: rockId,
              name: `Key priorities & L10 mappings for ${rockId}`,
              data: {
                groupId: this.vtoId,
                level10s: [],
                keypriorities: [],
                vtos: [this.vtoId]
              }
            })
          }
          return []
        }

        const { data } = object.data()
        this.linkedRocktoolsWithExistingKP = data.keypriorities
        this.linkedL10sWithExistingKP = data.level10s
        return data
      } catch (error) {
        this.$emit('set-message', { type: 'error', message: `Error loading linked items: ${error.message}` })
      } finally {
        this.isFetchingLinkedItems = false
      }
    },

    linkWithRocktool (rock) {
      if (!rock.email) {
        this.editRock(rock)
        return this.setMessage({ type: 'error', message: 'Key priority is un-assigned. Please assign to someone first.' })
      }
      this.keyPriorityIdToLinkWithRocktool = rock.id
      this.loadLinkedRocktoolsAndL10s(rock.id, true)
    },

    linkWithL10s (rock) {
      if (!rock.email) {
        this.editRock(rock)
        return this.setMessage({ type: 'error', message: 'Key priority is un-assigned. Please assign to someone first.' })
      }
      this.keyPriorityIdToLinkWithL10 = rock.id
      this.loadLinkedRocktoolsAndL10s(rock.id, true)
    },

    closeLinksDialog () {
      this.keyPriorityIdToLinkWithRocktool = null
      this.keyPriorityIdToLinkWithL10 = null
      this.linkedRocktoolsWithExistingKP = []
      this.linkedL10sWithExistingKP = []
    },

    async updateKeyPriorityRelations (ids) {
      try {
        this.isUpdatingLinks = true
        // find out newly selected or deleted links
        const checkAgainstList = this.keyPriorityIdToLinkWithRocktool ? this.linkedRocktoolsWithExistingKP : this.linkedL10sWithExistingKP
        const newlySelected = ids.filter(id => !checkAgainstList.includes(id))
        const deletedFrom = checkAgainstList.filter(id => !ids.includes(id))
        // update mapping obj
        const kpId = this.keyPriorityIdToLinkWithRocktool || this.keyPriorityIdToLinkWithL10
        this.keyPriorityIdToLinkWithRocktool ? await this.dataManager.updateObject('kpMapping', kpId, {
          'data.keypriorities': ids
        }) : await this.dataManager.updateObject('kpMapping', kpId, { 'data.level10s': ids })
        this.closeLinksDialog()
        this.$emit('set-message', { type: 'success', message: 'Data updated' })
        // insert or delete items to particular item
        if (newlySelected.length > 0) this.updateKeyPriorityLinks(ADD_KP, kpId, newlySelected)
        if (deletedFrom.length > 0) this.updateKeyPriorityLinks(REMOVE_KP, kpId, deletedFrom)
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating relations: ${error.message}` })
      } finally {
        this.isUpdatingLinks = false
      }
    },

    updateKeyPriorityLinks (type, kpId, itemIds) {
      try {
        const keyPriority = this.data.rocks.quaterlyRocks.find(kp => kp.id === kpId)
        // trigger firebase function to add/remove from particular items
        this.toggleKeypriorityForItems({ type, keyPriority, linkedItems: itemIds.filter(id => id !== this.vtoId) })
        this.$analytics('visionandplan_used_internal_sync', { id: this.vtoId })
      } catch (error) {
        this.$emit('set-message', {
          type: 'error',
          message: `Error adding key priority to selected item(s): ${error.message}`
        })
      }
    },

    async deleteRock (index) {
      const rockToDelete = this.data.rocks.quaterlyRocks[index]
      await this.loadLinkedRocktoolsAndL10s(rockToDelete.id)

      const linkedItems = [...this.linkedRocktoolsWithExistingKP, ...this.linkedL10sWithExistingKP]
      if (linkedItems.length > 0) {
        this.updateKeyPriorityLinks(REMOVE_KP, rockToDelete.id, linkedItems)
        this.dataManager.hardDelete('kpMapping', rockToDelete.id)
      }

      this.data.rocks.quaterlyRocks.splice(index, 1)
      this.saveVTO()
    },

    completeRock (rock) {
      const isCompleted = rock.status === 'Complete' || rock.isCompleted
      this.updateRockFields({
        ...rock,
        status: isCompleted ? 'Incomplete' : 'Complete'
      })
    },

    printVTO () {
      this.beforePrint()
      setTimeout(() => {
        window.print()
        this.afterPrint()
        this.$analytics('visionandplan_printed', { id: this.vtoId })
      }, 500)
    },

    beforePrint () {
      this.isPrinting = true
    },

    afterPrint () {
      this.isPrinting = false
    },

    openImportExportModal () {
      this.dataJSON = JSON.stringify(this.data, null, 4)
      this.showImportExportModal = true
    },

    async updateKeyPrioritiesJSON (json) {
      try {
        this.isUpdatingVTOJSON = true
        const data = JSON.parse(json)
        this.data = data
        await this.dataManager.updateObject('vtos', this.vtoId, { data })
        this.closeImportExportModal()
        this.setMessage({ type: 'success', message: 'Data updated' })
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating data: ${error.message}` })
      } finally {
        this.isUpdatingVTOJSON = false
      }
    },

    closeImportExportModal () {
      this.showImportExportModal = false
    }
  }
}
</script>

<style type="scss" scoped>
.eos-vto__wrapper {
  overflow-y: auto;
  height: calc(100vh - 140px);
}

.eos-vto__vision,
.eos-vto__traction {
  min-height: 100%;
}

.eos-vto__content--editable {
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.eos-vto__area--editable {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.eos-vto__area--editable:hover {
  background-color: #F5F5F5;
  transition: 0.3s all;
}

.eos-vto__core-values,
.eos-vto__core-focus,
.eos-vto__target,
.eos-vto__marketing-starategy {
  min-width: 200px;
  min-height: 102px;
}

.eos-vto__version--old .eos-vto__area--editable:hover {
  background-color: transparent;
  cursor: default;
}

.eos-vto__version--old >>> .v-chip--clickable,
.eos-vto__version--old >>> .v-input {
  pointer-events: none;
}

.eos-vto__version--old >>> .v-btn {
  display: none !important;
}

.eos-vto__version-select {
  width: 100px;
}

.eos-vto__version-select >>> .v-select__selections {
  margin-right: -8px;
}

@media print {
  @page {
    size: A3 landscape;
  }

  img {
    height: 90%;
    margin: 0;
    padding: 0;
  }

  .eos-vto__wrapper {
    overflow-y: visible;
    height: 100%;
  }

  .eos-vto__vision,
  .eos-vto__traction {
    border: 1px solid #000;
    min-height: calc(100vh - 140px);
  }

  .eos-vto__vision .row {
    min-height: 100%;
  }

  ::v-deep .eos-vto__toolbar {
    border-bottom: none;
  }
}
</style>
